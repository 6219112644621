import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Home from '../pkwebsite/Home';
import Window from '../os/Window';
import Store from '../pkwebsite/Store';
import Music from '../pkwebsite/Music';
import Contact from '../pkwebsite/Contact';
import VerticalNavbar from '../pkwebsite/VerticalNavbar';
import useInitialWindowSize from '../../hooks/useInitialWindowSize';

export interface PkWebsiteProps extends WindowAppProps {}

const PkWebsite: React.FC<PkWebsiteProps> = (props) => {
    const { initWidth, initHeight } = useInitialWindowSize({ margin: 100 });

    return (
        <Window
            top={24}
            left={56}
            width={initWidth}
            height={initHeight}
            windowTitle="monty.pk website"
            windowBarIcon="windowExplorerIcon"
            closeWindow={props.onClose}
            onInteract={props.onInteract}
            minimizeWindow={props.onMinimize}
            bottomLeftText={'© Copyright 2024 monty.pk Inc.'}
            resizable={true} // Add resizable prop
        >
            <Router>
                <div className="site-page">
                    <VerticalNavbar />
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/store" element={<Store />} />
                        <Route path="/music" element={<Music />} />
                        <Route path="/contact" element={<Contact />} />
                        <Route path="*" element={<Navigate to="/" replace />} />
                    </Routes>
                </div>
            </Router>
        </Window>
    );
};

export default PkWebsite;