import React, { useState } from 'react';
import Window from '../os/Window';
import IconLink from '../os/IconLink';
import MyComputer from '../../assets/pictures/MyComputer.png';

export interface ThisComputerProps extends WindowAppProps {}

const ThisComputerApp: React.FC<ThisComputerProps> = (props) => {
    const [selectedDescription, setSelectedDescription] = useState<JSX.Element>(
        <p style={styles.photoText}>Select an item to view its description</p>
    );
    const [selectedItem, setSelectedItem] = useState<string>('');

    const handleSelect = (name: string) => {
        setSelectedItem(name);
        let description: JSX.Element;
        switch (name) {
            case '3½ Floppy (A:)':
                description = (
                    <p style={styles.photoText}>
                        <b>3½ Floppy<br />
                        (A:)</b><br /><br />
                        3½ Inch Floppy Disk
                    </p>
                );
                break;
            case '(C:)':
                description = (
                    <p style={styles.photoText}>
                        <b>(C:)<br />
                        Local Disk</b><br /><br />
                        Capacity: 299 MB<br /><br />
                        Used: 257 MB<br />
                        Free: 42.5 MB
                    </p>
                );
                break;
            case 'Documents':
                description = (
                    <p style={styles.photoText}>
                        <b>Documents</b><br /><br />
                        System Folder<br />
                    </p>
                );
                break;
            default:
                description = <p style={styles.photoText}>Select an item to view its description</p>;
        }
        setSelectedDescription(description);
    };

    return (
        <Window
            top={100}
            left={200}
            width={600}
            height={410}
            windowBarIcon="computerSmall"
            windowTitle="My Computer"
            closeWindow={props.onClose}
            onInteract={props.onInteract}
            minimizeWindow={props.onMinimize}
            resizable={false}
        >
            <div style={styles.container}>
                <div style={styles.photoContainer}>
                    <img src={MyComputer} alt="Top Left" style={styles.photo} />
                    <div style={styles.photoTextBox}>
                        {selectedDescription}
                    </div>
                </div>
                <div style={styles.shortcutContainer}>
                    <IconLink
                        icon="floppyDrive"
                        shortcutName="3½ Floppy (A:)"
                        onSelect={handleSelect}
                        isSelected={selectedItem === "3½ Floppy (A:)"}
                    />
                    <IconLink
                        icon="diskDrive"
                        shortcutName="(C:)"
                        onSelect={handleSelect}
                        isSelected={selectedItem === "(C:)"}
                    />
                    <IconLink
                        icon="documents"
                        shortcutName="Documents"
                        onSelect={handleSelect}
                        isSelected={selectedItem === "Documents"}
                    />
                </div>
            </div>
        </Window>
    );
};

const styles: StyleSheetCSS = {
    container: {
        position: 'relative',
        height: '100%',
        width: '100%',
    },
    photoContainer: {
        position: 'absolute',
        top: 0,
        left: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        textAlign: 'left',
        overflowY: 'auto', // Add overflow for scrollable content
        maxHeight: '100%', // Set max height to parent container height
    },
    photo: {
        width: 200, // Adjust the width as needed
        height: 200, // Adjust the height as needed
    },
    photoTextBox: {
        marginTop: 0, // Adjust the space between the image and text box as needed
        paddingLeft: 25, // Move the text to the right
        backgroundColor: 'white', // Background color for the text box
        width: '45%',
        textAlign: 'left',
        borderRight: '2px white', // Right-side border
        wordWrap: 'break-word', // Wrap long words
    },
    photoText: {
        fontSize: 12, // Adjust the font size as needed
        whiteSpace: 'pre-wrap', // Respect line breaks
    },
    shortcutContainer: {
        position: 'absolute',
        top: 20,
        left: 260,
        display: 'flex',
        gap: 20,
    },
};

export default ThisComputerApp;