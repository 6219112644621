import React, { useState, useCallback, useRef, useEffect } from 'react';
import { IconName } from '../../assets/icons';
import colors from '../../constants/colors';
import { Icon } from '../general';

export interface IconLinkProps {
    icon: IconName;
    shortcutName: string;
    invertText?: boolean;
    onSelect: (name: string) => void; // Callback to handle selection
    isSelected: boolean; // Pass the selection state as a prop
}

const IconLink: React.FC<IconLinkProps> = ({
    icon,
    shortcutName,
    invertText,
    onSelect,
    isSelected,
}) => {
    const [shortcutId, setShortcutId] = useState('');
    const containerRef = useRef<any>(null);

    const requiredIcon = require(`../../assets/icons/${icon}.png`);
    const [doubleClickTimerActive, setDoubleClickTimerActive] = useState(false);

    const getShortcutId = useCallback(() => {
        const shortcutId = shortcutName.replace(/\s/g, '');
        return `icon-link-${shortcutId}`;
    }, [shortcutName]);

    useEffect(() => {
        setShortcutId(getShortcutId());
    }, [getShortcutId]);

    const handleClickIcon = useCallback(() => {
        if (doubleClickTimerActive) {
            setDoubleClickTimerActive(false);
            return;
        }
        onSelect(shortcutName); // Call the onSelect callback
        setDoubleClickTimerActive(true);
        setTimeout(() => {
            setDoubleClickTimerActive(false);
        }, 300);
    }, [doubleClickTimerActive, onSelect, shortcutName]);

    return (
        <div
            id={shortcutId}
            style={styles.appShortcut}
            onMouseDown={handleClickIcon}
            ref={containerRef}
        >
            <div id={shortcutId} style={styles.iconContainer}>
                <div
                    id={shortcutId}
                    className="desktop-shortcut-icon"
                    style={Object.assign(
                        {},
                        styles.iconOverlay,
                        isSelected && styles.checkerboard,
                        isSelected && {
                            WebkitMask: `url(${requiredIcon}) no-repeat center / contain`,
                            mask: `url(${requiredIcon}) no-repeat center / contain`,
                        }
                    )}
                />
                <Icon icon={icon} style={styles.icon} />
            </div>
            <div
                id={shortcutId}
                style={Object.assign(
                    {},
                    styles.shortcutTextContainer,
                    isSelected && { backgroundColor: colors.blue }
                )}
            >
                <p
                    id={shortcutId}
                    style={Object.assign(
                        {},
                        styles.shortcutText,
                        isSelected ? { color: 'white' } : { color: 'black' }
                    )}
                >
                    {shortcutName}
                </p>
            </div>
        </div>
    );
};

const styles: StyleSheetCSS = {
    appShortcut: {
        position: 'relative',
        width: 56,
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        textAlign: 'center',
        marginRight: 20, // Add spacing between icons
    },
    shortcutTextContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    shortcutText: {
        cursor: 'pointer',
        textOverflow: 'ellipsis', // Use ellipsis for long text
        whiteSpace: 'nowrap', // Prevent line breaks
        overflow: 'hidden', // Hide overflow text
        fontFamily: 'MSSerif',
        fontSize: 12,
        paddingRight: 2,
        paddingLeft: 2,
    },
    iconContainer: {
        cursor: 'pointer',
        paddingBottom: 3,
        position: 'relative', // Ensure position relative for absolute positioning of overlay
    },
    iconOverlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: 45,
        height: 45,
    },
    checkerboard: {
        width: 45,
        height: 45,
        backgroundImage: `linear-gradient(45deg, ${colors.blue} 25%, transparent 25%),
        linear-gradient(-45deg, ${colors.blue} 25%, transparent 25%),
        linear-gradient(45deg, transparent 75%, ${colors.blue} 75%),
        linear-gradient(-45deg, transparent 75%, ${colors.blue} 75%)`,
        backgroundSize: `2px 2px`,
        backgroundPosition: `0 0, 0 1px, 1px -1px, -1px 0px`,
        pointerEvents: 'none',
    },
    icon: {
        width: 45,
        height: 45,
    }
};

export default IconLink;