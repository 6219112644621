import React from 'react';
// import DesktopShortcut from '../os/DesktopShortcut';
import Window from '../os/Window';

export interface InternetExplorerProps extends WindowAppProps {}

const InternetExplorerApp: React.FC<InternetExplorerProps> = (props) => {

    return (
        <Window
            top={100}
            left={300}
            width={600}
            height={430}
            windowBarIcon="windowExplorerIcon"
            windowTitle="Internet Explorer"
            bottomLeftText={'© Copyright 2024 monty.pk Inc.'}
            closeWindow={props.onClose}
            onInteract={props.onInteract}
            minimizeWindow={props.onMinimize}
            resizable={true}
        >
            <div className="site-page">
                <iframe
                    src="https://oldgoogle.neocities.org/1998/"
                    title="google"
                    width="100%"
                    height="100%"
                />
            </div>
        </Window>
    );
};

export default InternetExplorerApp;
