import React, { useState, useRef, useEffect } from 'react';
import PlayIcon from '../../assets/icons/play.png';
import PauseIcon from '../../assets/icons/pause.png';
import RewindIcon from '../../assets/icons/rewind.png';
import ForwardIcon from '../../assets/icons/forward.png';
import CDIcon from '../../assets/icons/cd.png';
import colors from '../../constants/colors';
import { motion, Variants } from 'framer-motion';

interface Song {
    title: string;
    src: string;
    subtitle: string;
}

export interface AlbumPlayerProps {
    playlist: Song[];
    playingPlayer: string | null;
    setPlayingPlayer: React.Dispatch<React.SetStateAction<string | null>>;
    playerId: string;
}

const AlbumPlayer: React.FC<AlbumPlayerProps> = ({ playlist, playingPlayer, setPlayingPlayer, playerId }) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentSong, setCurrentSong] = useState<Song>(playlist[0]);
    const audioRef = useRef(new Audio(currentSong.src));
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(1);

    useEffect(() => {
        audioRef.current.pause();
        audioRef.current = new Audio(currentSong.src);
        setCurrentTime(0);
        if (isPlaying && playingPlayer === playerId) audioRef.current.play();

        audioRef.current.addEventListener('timeupdate', () => {
            setCurrentTime(audioRef.current.currentTime);
            setDuration(audioRef.current.duration);
            if (audioRef.current.currentTime === audioRef.current.duration) {
                setIsPlaying(false);
            }
        });
    }, [currentSong]);

    useEffect(() => {
        if (playingPlayer !== playerId) {
            audioRef.current.pause();
            setIsPlaying(false);
        }
    }, [playingPlayer, playerId]);

    const togglePlay = () => {
        if (isPlaying) {
            audioRef.current.pause();
            setIsPlaying(false);
            setPlayingPlayer(null);
        } else {
            audioRef.current.play();
            setIsPlaying(true);
            setPlayingPlayer(playerId);
        }
    };

    const fastForward = () => {
        audioRef.current.currentTime += 15;
    };

    const fastRewind = () => {
        audioRef.current.currentTime = 0;
    };

    const formatTime = (time: number) => {
        if (isNaN(time) || time === 1) return '..:..'; // Display ..:.. if duration is not yet loaded
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time - minutes * 60);
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    const selectSong = (song: Song) => {
        setCurrentSong(song);
        setIsPlaying(true);
        setPlayingPlayer(playerId);
    };

    return (
        <div style={styles.container}>
            {/* Main Player Section */}
            <div style={styles.musicPlayerContainer} className="music-controller-container">
                <div style={styles.playerStart}>
                    <motion.img
                        variants={vars}
                        animate={isPlaying ? 'play' : 'pause'}
                        src={CDIcon}
                        style={styles.cd}
                        onMouseDown={togglePlay}
                        alt=""
                    />
                </div>
                <div style={styles.playerInfo}>
                    <div style={styles.progressContainer}>
                        <p style={styles.time}>
                            <b>{formatTime(currentTime)}</b>
                        </p>
                        <div style={styles.progressBarContainer}>
                            <div
                                style={{
                                    ...styles.progress,
                                    transform: `scaleX(${currentTime / duration})`,
                                }}
                            />
                        </div>
                        <p style={styles.time}>
                            <b>{formatTime(duration)}</b>
                        </p>
                    </div>
                    <div style={styles.playerBottom}>
                        <div style={styles.info}>
                            <h3>{currentSong.title}</h3>
                            <p>{currentSong.subtitle}</p>
                        </div>
                        <div style={styles.playerControls}>
                            <div style={styles.controlButton} className='site-button' onMouseDown={fastRewind}>
                                <img src={RewindIcon} style={styles.controlIcon} alt="Rewind" />
                            </div>
                            <div style={styles.controlButton} className='site-button' onMouseDown={togglePlay}>
                                <img
                                    src={isPlaying ? PauseIcon : PlayIcon}
                                    style={styles.controlIcon}
                                    alt={isPlaying ? 'Pause' : 'Play'}
                                />
                            </div>
                            <div style={styles.controlButton} className='site-button' onMouseDown={fastForward}>
                                <img src={ForwardIcon} style={styles.controlIcon} alt="Forward" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Playlist Container Positioned Below the Player */}
            <div style={styles.playlistContainer}>
                {playlist.map((song, index) => (
                    <div
                        key={index}
                        onClick={() => selectSong(song)}
                        style={{
                            ...styles.playlistItemContainer,
                            backgroundColor: song.title === currentSong.title ? colors.lightGray : 'transparent',
                        }}
                    >
                        <div
                            style={{
                                ...styles.trackNumber,
                                fontWeight: song.title === currentSong.title ? 'bold' : 'normal',
                            }}
                        >
                            {index + 1}
                        </div>
                        <div
                            style={{
                                ...styles.trackTitle,
                                fontWeight: song.title === currentSong.title ? 'bold' : 'normal',
                            }}
                        >
                            {song.title}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

const vars: Variants = {
    play: {
        rotate: 360,
        transition: { repeat: Infinity, duration: 0.9, ease: 'linear' },
    },
    pause: {
        rotate: -360,
        transition: { duration: 0.6, ease: 'easeOut' },
    },
};

const styles: { [key: string]: React.CSSProperties } = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    musicPlayerContainer: {
        flexDirection: 'row',
        width: '100%',
        borderRadius: 4,
    },
    playlistContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100',
    },
    playlistItemContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: 'calc(100% - 2px)', // Make container 2px shorter
        //padding: '5px',
        //width: '100%',
        borderBottom: `1px solid ${colors.darkGray}`, // Bottom border
        borderLeft: `1px solid ${colors.darkGray}`,   // Left border
        borderRight: `1px solid ${colors.darkGray}`,  // Right border
        //paddingLeft: '10px', 
        //paddingRight: 'px',
    },
    trackNumber: {
        width: '95px', // Adjust as needed
        height: '30px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
//        textAlign: 'center',
        //paddingRight: '10px',
        fontFamily: 'Millennium, sans-serif',
        borderRight: `1px solid ${colors.darkGray}`, // Divider between number and title
    },
    trackTitle: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
    //    textAlign: 'left',
        fontFamily: 'Millennium, sans-serif',
        paddingLeft: '16px',
    },
    playlistItem: {
        cursor: 'pointer',
        //padding: '10px 0',
        fontSize: 14,
        color: colors.black,
    },
    playerControls: {
        justifyContent: 'center',
        alignItems: 'center',
        background: 'red',
    },
    progress: {
        width: '100%',
        transform: `scaleX(0)`,
        height: 6,
        background: colors.black,
        transformOrigin: 'left',
    },
    progressBarContainer: {
        width: '100%',
        height: 6,
        background: colors.lightGray,
        marginLeft: 8,
        marginRight: 8,
    },
    progressContainer: {
        flexDirection: 'row',
        width: '100%',
        alignItems: 'center',
    },
    playerInfo: {
        flex: 1,
        overflow: 'hidden',
        border: `1px solid ${colors.darkGray}`,
        borderTopWidth: 0,
        flexDirection: 'column',
        borderBottomWidth: 0,
        padding: 16,
        paddingTop: 18,
    },
    info: {
        flexDirection: 'column',
    },
    playerBottom: {
        paddingTop: 2,
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    playerStart: {
        flexShrink: 1,
        justifyContent: 'center',
        alignItems: 'center',
        width: 96,
    },
    controlButton: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    controlIcon: {
        width: 20,
        height: 20,
    },
    time: {
        fontSize: 14,
    },
    cd: {
        width: 56,
        height: 56,
        cursor: 'pointer',
    },
};

export default AlbumPlayer;